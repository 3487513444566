import { defineStore } from "pinia"

function getLocalStorageItem(key, defaultValue = null) {
  try {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : defaultValue
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error)
    return defaultValue
  }
}

function setLocalStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export const platformStore = defineStore("testStore", {
  state: () => ({
    filteredSuggestions: getLocalStorageItem("filteredSuggestions", []),
    needSaveLogin: localStorage.getItem("checkNoLogin"),
  }),
  actions: {
    changeLogin(e) {
      this.needSaveLogin = e
      setLocalStorageItem("checkNoLogin", e)
    },
    refreshList() {
      this.filteredSuggestions = getLocalStorageItem("filteredSuggestions", [])
    },
  },
})
