import axios from "axios"
// import { showToast  } from 'vant';
import "vant/lib/index.css"
import { showToast } from "vant"
import bus from "@/lib/bus"
import { platformStore } from "@/store/index"
// 创建一个 axios 实例
const service = axios.create({
  baseURL: "/", // 所有的请求地址前缀部分
  timeout: 5000, // 请求超时时间毫秒
  // withCredentials: true, // 异步请求携带cookie
})

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    if (response.data.code == 401) {
      showToast("登录信息过期,请重新登录")
      bus.$emit("loginOut") //未登录调取登录接口
      // ---------------此段代码为记住账号功能----------------
      if (localStorage.getItem("loginType") == "filteredSuggestions") {
        let filteredSuggestions = JSON.parse(
          localStorage.getItem("filteredSuggestions")
        )
        let mobile = localStorage.getItem("mobile")
        filteredSuggestions.forEach((item) => {
          if (item.id == mobile) {
            filteredSuggestions.splice(filteredSuggestions.indexOf(item), 1)
            localStorage.removeItem("token")
          }
        })
        localStorage.setItem(
          "filteredSuggestions",
          JSON.stringify(filteredSuggestions)
        )

        platformStore().refreshList()
        // ---------------此段代码为记住账号功能----------------
      }
    }
    const dataAxios = response.data
    return dataAxios
  },
  function (error) {
    showToast("网络超时,请刷新页面或稍后重试")
    return Promise.reject(error)
  }
)

export default service
