import HeaderContainer from "@/components/HeaderContainer/HeaderContainer.vue";
import LoginPage from "@/components/LoginPage/LoginPage.vue";
import { ref } from "vue";
import bus from "./lib/bus";
export default {
  name: "App",
  components: {
    HeaderContainer,
    LoginPage
  },
  setup() {
    const payKeep = ref("paypage");
    bus.$on("payKeep", e => {
      payKeep.value = e;
    });
    // 登录弹窗
    const show = ref(false);
    const showLogins = () => {
      show.value = true;
    };
    // 联系客服
    bus.$on("showKf", () => {
      show.value = true;
      setTimeout(() => {
        bus.$emit("showKfSec");
      }, 0);
    });
    const closeLoginContainer = () => {
      show.value = false;
    };
    const listenClose = () => {
      bus.$emit("scrollGoToTop");
    };
    // 登录成功
    const token = ref("");
    bus.$on("sendToken", e => {
      token.value = e;
      show.value = false;
    });
    // 调取登录弹窗
    bus.$on("click", () => {
      showLogins();
    });
    // 监听是否进充值页
    const isPay = ref(false);
    bus.$on("showPayHeader", () => {
      isPay.value = true;
    });
    bus.$on("closePayHeader", () => {
      isPay.value = false;
    });
    const destktopPopup = ref(false); //pwa引导页弹窗
    const isWechat = ref(false);
    bus.$on("deskTop", () => {
      // 判断当前是否为微信环境
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        isWechat.value = true;
      }
      destktopPopup.value = true;
    });
    return {
      destktopPopup,
      listenClose,
      isPay,
      token,
      show,
      showLogins,
      closeLoginContainer,
      payKeep,
      isWechat
    };
  }
};