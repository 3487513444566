import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelDynamic as _vModelDynamic, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from './../../assets/image/kf.jpeg';
const _hoisted_1 = {
  key: 0,
  class: "login-box"
};
const _hoisted_2 = {
  class: "header-container"
};
const _hoisted_3 = {
  class: "header-btn"
};
const _hoisted_4 = {
  key: 0,
  class: "cellular-network"
};
const _hoisted_5 = {
  class: "tabs-list-container"
};
const _hoisted_6 = {
  class: "login-btn"
};
const _hoisted_7 = {
  class: "checkbox-container"
};
const _hoisted_8 = {
  class: "checkbox-title"
};
const _hoisted_9 = {
  key: 1,
  class: "login-tabs-container"
};
const _hoisted_10 = {
  class: "tabs-list-container"
};
const _hoisted_11 = {
  class: "input-container"
};
const _hoisted_12 = {
  class: "input-body"
};
const _hoisted_13 = {
  class: "input-container"
};
const _hoisted_14 = {
  class: "input-body"
};
const _hoisted_15 = ["disabled"];
const _hoisted_16 = {
  class: "checkbox-container"
};
const _hoisted_17 = {
  class: "checkbox-container"
};
const _hoisted_18 = {
  class: "checkbox-title"
};
const _hoisted_19 = {
  class: "login-btn"
};
const _hoisted_20 = {
  class: "tabs-list-container"
};
const _hoisted_21 = {
  class: "input-container"
};
const _hoisted_22 = {
  class: "input-body"
};
const _hoisted_23 = {
  key: 0,
  class: "suggestions-list"
};
const _hoisted_24 = ["onMousedown"];
const _hoisted_25 = ["onMousedown"];
const _hoisted_26 = {
  class: "input-container"
};
const _hoisted_27 = {
  class: "input-body"
};
const _hoisted_28 = ["type"];
const _hoisted_29 = {
  class: "checkbox-container-list"
};
const _hoisted_30 = {
  class: "checkbox-container"
};
const _hoisted_31 = {
  class: "checkbox-container"
};
const _hoisted_32 = {
  class: "checkbox-container"
};
const _hoisted_33 = {
  class: "checkbox-title"
};
const _hoisted_34 = {
  class: "login-btn"
};
const _hoisted_35 = {
  key: 1,
  class: "kf-code-container"
};
const _hoisted_36 = {
  key: 2,
  class: "tips-container"
};
const _hoisted_37 = {
  class: "tips-btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_checkbox = _resolveComponent("van-checkbox");
  const _component_van_tab = _resolveComponent("van-tab");
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_tabs = _resolveComponent("van-tabs");
  return _openBlock(), _createElementBlock("div", null, [!$setup.showKfCode && !$setup.showTips ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "close",
    onClick: _cache[0] || (_cache[0] = $event => $setup.closeLoginContainer())
  }), _createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = $event => $setup.kfBtn()),
    class: "some-trouble"
  }, "遇到问题？")])]), $setup.cellularCanUse ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[36] || (_cache[36] = _createElementVNode("div", {
    class: "cellular-phone"
  }, "188888888", -1)), _cache[37] || (_cache[37] = _createElementVNode("div", {
    class: "cellular-company"
  }, "中国移动提供支持", -1)), _createElementVNode("div", _hoisted_6, [_createElementVNode("button", {
    onClick: _cache[2] || (_cache[2] = $event => $setup.LoginByCellular())
  }, "本机号码一键登录")]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_van_checkbox, {
    modelValue: $setup.checked,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $setup.checked = $event),
    "checked-color": "rgb(136, 73, 246)",
    shape: "square",
    "icon-size": "16"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_cache[34] || (_cache[34] = _createTextVNode(" 阅读并同意 ")), _createElementVNode("span", {
      onClick: _cache[3] || (_cache[3] = _withModifiers($event => $setup.goYsxy(), ["stop"])),
      class: "ruler-title"
    }, "《青瓷游戏隐私政策 》"), _cache[35] || (_cache[35] = _createTextVNode("、")), _createElementVNode("span", {
      class: "ruler-title",
      onClick: _cache[4] || (_cache[4] = _withModifiers($event => $setup.goYhxy(), ["stop"]))
    }, "《青瓷游戏用户服务协议》")])]),
    _: 1
  }, 8, ["modelValue"])])])])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_van_tabs, {
    active: $setup.loginActive,
    "onUpdate:active": _cache[30] || (_cache[30] = $event => $setup.loginActive = $event),
    "line-width": "28",
    animated: "",
    color: "#A983FF",
    "title-active-color": "#8383FF",
    "title-inactive-color": "#464646"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tab, {
      title: "短信登录"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_withDirectives(_createElementVNode("input", {
        type: "tel",
        placeholder: "请输入手机号",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $setup.phoneInput = $event),
        onFocusout: _cache[7] || (_cache[7] = (...args) => _ctx.inputFocusOut && _ctx.inputFocusOut(...args))
      }, null, 544), [[_vModelText, $setup.phoneInput]]), _cache[38] || (_cache[38] = _createElementVNode("div", {
        class: "input-title"
      }, [_createElementVNode("div", {
        class: "phone"
      })], -1))])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_withDirectives(_createElementVNode("input", {
        type: "tel",
        placeholder: "请输入验证码",
        maxlength: "6",
        class: "sec-input",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $setup.codeInput = $event)
      }, null, 512), [[_vModelText, $setup.codeInput]]), _cache[39] || (_cache[39] = _createElementVNode("div", {
        class: "input-title"
      }, [_createElementVNode("div", {
        class: "sms"
      })], -1)), _createElementVNode("button", {
        class: "msg-btn",
        disabled: $setup.disabled,
        onClick: _cache[9] || (_cache[9] = $event => $setup.sendMsg())
      }, _toDisplayString($setup.send), 9, _hoisted_15)])]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_van_checkbox, {
        modelValue: $setup.notLoginChecked,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => $setup.notLoginChecked = $event),
        "checked-color": "rgb(136, 73, 246)",
        shape: "square",
        "icon-size": "16",
        onChange: _cache[11] || (_cache[11] = $event => $setup.checkNoLogin())
      }, {
        default: _withCtx(() => _cache[40] || (_cache[40] = [_createElementVNode("div", {
          class: "checkbox-title"
        }, "30天内免登录", -1)])),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_van_checkbox, {
        modelValue: $setup.checked,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => $setup.checked = $event),
        "checked-color": "rgb(136, 73, 246)",
        shape: "square",
        "icon-size": "16"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_18, [_cache[41] || (_cache[41] = _createTextVNode(" 阅读并同意 ")), _createElementVNode("span", {
          onClick: _cache[12] || (_cache[12] = _withModifiers($event => $setup.goYsxy(), ["stop"])),
          class: "ruler-title"
        }, "《青瓷游戏隐私政策 》"), _cache[42] || (_cache[42] = _createTextVNode("、")), _createElementVNode("span", {
          class: "ruler-title",
          onClick: _cache[13] || (_cache[13] = _withModifiers($event => $setup.goYhxy(), ["stop"]))
        }, "《青瓷游戏用户服务协议》")])]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("button", {
        onClick: _cache[15] || (_cache[15] = (...args) => $setup.LoginBtn && $setup.LoginBtn(...args))
      }, "登录")])])]),
      _: 1
    }), _createVNode(_component_van_tab, {
      title: "密码登录"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_20, [_createElementVNode("form", null, [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "请输入账号",
        required: "",
        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => $setup.inputUserId = $event),
        onFocus: _cache[17] || (_cache[17] = $event => $setup.inputFocus()),
        onFocusout: _cache[18] || (_cache[18] = $event => $setup.outputFocus()),
        class: _normalizeClass([$setup.showSuggestions ? 'none-border' : ''])
      }, null, 34), [[_vModelText, $setup.inputUserId]]), $setup.showSuggestions && $setup.filteredSuggestions.length ? (_openBlock(), _createElementBlock("ul", _hoisted_23, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredSuggestions, (suggestion, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onMousedown: _withModifiers($event => $setup.selectSuggestion(suggestion), ["prevent"])
        }, [_createTextVNode(_toDisplayString(suggestion.id) + " ", 1), _createElementVNode("div", {
          class: "delete-icon",
          onMousedown: _withModifiers($event => $setup.deleteSuggestion(index), ["stop"])
        }, [_createVNode(_component_van_icon, {
          name: "cross"
        })], 40, _hoisted_25)], 40, _hoisted_24);
      }), 128))])) : _createCommentVNode("", true), !$setup.showSuggestions && $setup.filteredSuggestions.length ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "select-icon",
        onClick: _cache[19] || (_cache[19] = _withModifiers($event => $setup.getSuggestion(), ["stop"]))
      }, [_createVNode(_component_van_icon, {
        name: "arrow-down"
      })])) : _createCommentVNode("", true), $setup.showSuggestions && $setup.filteredSuggestions.length ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: "select-icon",
        onClick: _cache[20] || (_cache[20] = _withModifiers($event => $setup.showSuggestions = !$setup.showSuggestions, ["stop"]))
      }, [_createVNode(_component_van_icon, {
        name: "arrow-up"
      })])) : _createCommentVNode("", true), _cache[43] || (_cache[43] = _createElementVNode("div", {
        class: "input-title"
      }, [_createElementVNode("div", {
        class: "phone"
      })], -1))])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_withDirectives(_createElementVNode("input", {
        type: $setup.pwType,
        placeholder: "请输入密码",
        name: "password",
        class: "sec-input",
        "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => $setup.userPw = $event),
        onKeydown: _cache[22] || (_cache[22] = (...args) => $setup.handleKeydownSms && $setup.handleKeydownSms(...args)),
        required: ""
      }, null, 40, _hoisted_28), [[_vModelDynamic, $setup.userPw]]), _cache[44] || (_cache[44] = _createElementVNode("div", {
        class: "input-title"
      }, [_createElementVNode("div", {
        class: "password"
      })], -1))])])]), _createElementVNode("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, [_createVNode(_component_van_checkbox, {
        modelValue: $setup.notLoginChecked,
        "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => $setup.notLoginChecked = $event),
        "checked-color": "rgb(136, 73, 246)",
        shape: "square",
        "icon-size": "16",
        onChange: _cache[24] || (_cache[24] = $event => $setup.checkNoLogin())
      }, {
        default: _withCtx(() => _cache[45] || (_cache[45] = [_createElementVNode("div", {
          class: "checkbox-title"
        }, "30天内免登录", -1)])),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_31, [_createVNode(_component_van_checkbox, {
        modelValue: $setup.rememberPassword,
        "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => $setup.rememberPassword = $event),
        "checked-color": "rgb(136, 73, 246)",
        shape: "square",
        "icon-size": "16"
      }, {
        default: _withCtx(() => _cache[46] || (_cache[46] = [_createElementVNode("div", {
          class: "checkbox-title"
        }, "记住密码", -1)])),
        _: 1
      }, 8, ["modelValue"])])]), _createElementVNode("div", _hoisted_32, [_createVNode(_component_van_checkbox, {
        modelValue: $setup.checked,
        "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => $setup.checked = $event),
        "checked-color": "rgb(136, 73, 246)",
        shape: "square",
        "icon-size": "16"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_33, [_cache[47] || (_cache[47] = _createTextVNode(" 阅读并同意 ")), _createElementVNode("span", {
          onClick: _cache[26] || (_cache[26] = _withModifiers($event => $setup.goYsxy(), ["stop"])),
          class: "ruler-title"
        }, "《青瓷游戏隐私政策 》"), _cache[48] || (_cache[48] = _createTextVNode("、")), _createElementVNode("span", {
          class: "ruler-title",
          onClick: _cache[27] || (_cache[27] = _withModifiers($event => $setup.goYhxy(), ["stop"]))
        }, "《青瓷游戏用户服务协议》")])]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_34, [_createElementVNode("button", {
        onClick: _cache[29] || (_cache[29] = $event => $setup.LoginBtn())
      }, "登录")])])]),
      _: 1
    })]),
    _: 1
  }, 8, ["active"])]))])) : _createCommentVNode("", true), $setup.showKfCode ? (_openBlock(), _createElementBlock("div", _hoisted_35, [_cache[49] || (_cache[49] = _createStaticVNode("<div class=\"kf-code\" data-v-5ecf8987><div class=\"code-bgc\" data-v-5ecf8987><img src=\"" + _imports_0 + "\" data-v-5ecf8987></div><div class=\"code-title\" data-v-5ecf8987>扫码关注公众号</div><div class=\"code-title\" data-v-5ecf8987>轻松找客服</div></div>", 1)), _createElementVNode("div", {
    class: "code-btn",
    onClick: _cache[31] || (_cache[31] = $event => $setup.closeKfCode())
  }, "确认")])) : _createCommentVNode("", true), $setup.showTips ? (_openBlock(), _createElementBlock("div", _hoisted_36, [_cache[50] || (_cache[50] = _createElementVNode("div", {
    class: "tips-title"
  }, "温馨提示", -1)), _cache[51] || (_cache[51] = _createElementVNode("div", {
    class: "tips-content"
  }, " 请阅读并同意青瓷游戏的《用户协议》和《隐私政策》 ", -1)), _createElementVNode("div", _hoisted_37, [_createElementVNode("div", {
    class: "cancel-btn",
    onClick: _cache[32] || (_cache[32] = $event => $setup.showTips = false)
  }, "不同意"), _createElementVNode("div", {
    class: "confirm-btn",
    onClick: _cache[33] || (_cache[33] = $event => $setup.agreeBtn())
  }, "同意并继续")])])) : _createCommentVNode("", true)]);
}