import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "element-plus/theme-chalk/index.css"
import { createPinia } from "pinia"
import "./registerServiceWorker"
import VueLuckyCanvas from "@lucky-canvas/vue"
const pinia = createPinia() //创建pina实例
function setRemUnit() {
  const docEl = document.documentElement
  const clientWidth = docEl.clientWidth

  if (!clientWidth) return

  // 设置根元素的字体大小，假设设计稿宽度为750px
  // const baseSize = 75; // 750 / 10 = 75
  let fontSize = clientWidth / 10
  // 限制最大页面宽度为750px
  if (clientWidth > 750) {
    fontSize = 750 / 18
  } else if (clientWidth >= 500) {
    // 折叠屏
    fontSize = clientWidth / 18
  }

  // 设置一个最小字体大小，防止在小屏幕上字体过小
  const minFontSize = 12 // 你可以根据需要调整
  if (fontSize < minFontSize) {
    fontSize = minFontSize
  }

  docEl.style.fontSize = fontSize + "px"
}
// 初始化
setRemUnit()
// 窗口大小变化时重新设置根元素的字体大小
window.addEventListener("resize", setRemUnit)
const art = `
  ______       ______ .______    __           ___   ____    ____ 
 /  __  \\     /      ||   _  \\  |  |         /   \\  \\   \\  /   / 
|  |  |  |   |  ,----'|  |_)  | |  |        /  ^  \\  \\   \\/   /  
|  |  |  |   |  |     |   ___/  |  |       /  /_\\  \\  \\_    _/   
|  \`--'  '--.|  \`----.|  |      |  \`----. /  _____  \\   |  |     
 \\_____\\_____\\\\______|| _|      |_______|/__/     \\__\\  |__|     
`

console.log("%c" + art, "color:  rgba(131, 131, 255, 1); font-weight: bold;")

createApp(App).use(router).use(pinia).use(VueLuckyCanvas).mount("#app")
