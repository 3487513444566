import { createRouter, createWebHistory } from "vue-router"
import bus from "./../lib/bus"
const routes = [
  { path: "/", component: () => import("../views/Home.vue") },
  // 充值详情内页
  {
    path: "/pay",
    name: "paypage",
    component: () => import("../views/PayPage.vue"),
    meta: {
      keepAlive: true,
    },
  },
  // 充值详情内页防封
  {
    path: "/pay:id",
    name: "paypagesec",
    component: () => import("../views/PayPage.vue"),
  },
  // 充值类目选择页
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  // 充值类目选择页防封
  {
    path: "/home:id",
    name: "homesec",
    component: () => import("../views/Home.vue"),
  },
  //列表页
  {
    path: "/orderpage",
    name: "orderpage",
    component: () => import("../views/OrderPage.vue"),
  },
  // 兑换记录
  {
    path: "/exchangerecord",
    name: "exchangerecord",
    component: () => import("../views/ExChangeRecord.vue"),
  },
  // // 积分明细
  {
    path: "/integraldetail",
    name: "integraldetail",
    component: () => import("../views/IntegralDetail.vue"),
  },
  // sdk支付
  {
    path: "/sdk/pay",
    name: "sdkpay",
    component: () => import("../views/SdkPay.vue"),
  },
  // 充值平台支付详情页面（微信支付时使用）
  {
    path: "/gamepaypage",
    name: "gamepaypage",
    component: () => import("../views/GamePayPage.vue"),
  },
  // 青瓷玩家补款页
  {
    path: "/compensate",
    name: "compensate",
    component: () => import("../views/SupplementPage.vue"),
  },
  // 微信小游戏支付页面
  {
    path: "/wechatgamepay",
    name: "wechatgamepay",
    component: () => import("../views/WechatGamePayPage.vue"),
  },
  // 充值中心代付页
  {
    path: "/payforanother",
    name: "payforanother",
    component: () => import("../views/PaymentByOthers.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})
router.beforeEach((to, from) => {
  if (to.name == "paypage" && from.name == "home") {
    bus.$emit("payKeep", "paypage")
  } else {
    bus.$emit("payKeep", "")
  }

  if (to.name == "paypage") {
    bus.$emit("showPayHeader")
  }
})
export default router
