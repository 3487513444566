import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "app-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderContainer = _resolveComponent("HeaderContainer");
  const _component_LoginPage = _resolveComponent("LoginPage");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [$setup.isPay ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_HeaderContainer, {
    onShowLogin: $setup.showLogins,
    token: $setup.token,
    class: "header-fix"
  }, null, 8, ["onShowLogin", "token"]), _createVNode(_component_van_popup, {
    show: $setup.show,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $setup.show = $event),
    onClose: _cache[1] || (_cache[1] = $event => $setup.listenClose()),
    "close-on-click-overlay": false
  }, {
    default: _withCtx(() => [_createVNode(_component_LoginPage, {
      onCloseLoginContainer: $setup.closeLoginContainer
    }, null, 8, ["onCloseLoginContainer"])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $setup.destktopPopup,
    "onUpdate:show": _cache[4] || (_cache[4] = $event => $setup.destktopPopup = $event),
    "close-on-click-overlay": false
  }, {
    default: _withCtx(() => [!$setup.isWechat ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "show-m-list-bgc-sec",
      onClick: _cache[2] || (_cache[2] = $event => $setup.destktopPopup = false)
    })) : _createCommentVNode("", true), $setup.isWechat ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: "show-m-list-bgc-wechat",
      onClick: _cache[3] || (_cache[3] = $event => $setup.destktopPopup = false)
    })) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true), _createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_KeepAlive, {
      include: $setup.payKeep
    }, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: _ctx.$route.name
    })) : _createCommentVNode("", true)], 1032, ["include"])), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: _ctx.$route.name
    })) : _createCommentVNode("", true)]),
    _: 1
  })], 64);
}