import "core-js/modules/es.array.push.js";
import { ref, onMounted, watch } from "vue";
import { gameLogin, sms, smsLogin } from "@/api/api";
import { showToast, Checkbox, CheckboxGroup } from "vant";
import "vant/lib/index.css";
import bus from "@/lib/bus";
import { platformStore } from "@/store/index";
export default {
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  setup(props, context) {
    // tabs序号
    const loginActive = ref(1);
    // 关闭弹窗
    const closeLoginContainer = () => {
      context.emit("closeLoginContainer");
      clearInput();
    };
    const clearInput = () => {
      phoneInput.value = "";
      codeInput.value = "";
      userPw.value = "";
      inputUserId.value = "";
    };
    // 账号登录
    const inputUserId = ref("");
    const userPw = ref("");
    const gameLogins = () => {
      if (suggestionType.value) {
        bus.$emit("login");
        inputUserId.value = "";
        userPw.value = "";
        bus.$emit("sendToken", localStorage.getItem("token"));
        setTimeout(() => {
          suggestionType.value = false;
        }, 500);
        return;
      }
      if (!inputUserId.value || !userPw.value) {
        return showToast("账号信息填写错误");
      }
      let prams = {
        game_id: 1,
        account: inputUserId.value,
        password: userPw.value
      };
      gameLogin(prams).then(res => {
        if (res.code != 200) {
          return showToast(res.message);
        }
        // 记住密码逻辑部分
        if (rememberPassword.value) {
          let query = inputUserId.value;
          let tokenLogin = res.data.access_token;
          let exists = false;
          for (const suggestion of filteredSuggestions.value) {
            if (suggestion.id == query) {
              exists = true;
              break; // 找到匹配项后立即退出循环
            }
          }
          if (!exists) {
            // 如果不存在，则添加到 filteredSuggestions 数组中
            filteredSuggestions.value.push({
              id: query,
              token: tokenLogin
            });
            localStorage.setItem("filteredSuggestions", JSON.stringify(filteredSuggestions.value));
          }
        } else localStorage.removeItem("loginType");
        checkNoLogin();
        localStorage.setItem("mobile", inputUserId.value);
        clearInput();
        localStorage.setItem("token", res.data.access_token);

        // bus.$emit('mobile', res.data.mobile)
        showToast("登录成功");
        bus.$emit("sendToken", res.data.access_token);
        bus.$emit("login");
      });
    };
    // 发送短信验证码
    const send = ref("发送"); // 短信验证码按钮信息
    const disabled = ref(false);
    const phoneInput = ref("");
    const codeInput = ref("");
    const timer = ref(60);
    const sendMsg = () => {
      if (!phoneInput.value) {
        return showToast("账号信息未填写");
      }
      disabled.value = true;
      let auth_timer = setInterval(() => {
        timer.value--;
        send.value = `${timer.value}秒`;
        if (timer.value <= 0) {
          disabled.value = false;
          send.value = "重新发送";
          timer.value = 60;
          clearInterval(auth_timer);
        }
      }, 1000);
      let prams = {
        mobile: phoneInput.value,
        channel: "login"
      };
      sms(prams).then(() => {
        showToast("发送成功");
      });
    };
    // 短信验证码登录
    const loginSms = () => {
      if (!phoneInput.value || !codeInput.value) {
        return showToast("账号信息未填写");
      }
      let prams = {
        account: phoneInput.value,
        code: codeInput.value,
        game_id: 1
      };
      smsLogin(prams).then(res => {
        if (res.code == 200) {
          checkNoLogin();
          localStorage.setItem("mobile", phoneInput.value);
          clearInput();
          localStorage.setItem("token", res.data.access_token);
          showToast("登录成功");
          bus.$emit("sendToken", res.data.access_token);
          bus.$emit("login");
        } else {
          showToast(res.message);
        }
      });
    };
    // 登录按钮点击
    const LoginBtn = () => {
      if (!checked.value) {
        // showToast("请先同意下方《青瓷游戏隐私政策》、《青瓷游戏用户服务协议》")
        showTips.value = true;
        return;
      }
      if (loginActive.value == 1) {
        gameLogins();
      } else {
        loginSms();
      }
    };
    // 监听输入框的回车键
    const handleKeydownSms = event => {
      if (event.key === "Enter" || event.keyCode === 13) {
        event.preventDefault(); //
        LoginBtn();
      }
    };
    // 协议确认
    const checked = ref(false);
    const goYsxy = () => {
      window.open("https://law.qingcigame.com/zh-cn/privacy_policy.html", "_blank’");
    };
    const goYhxy = () => {
      window.open("https://law.qingcigame.com/zh-cn/service_agreement.html", "_blank’");
    };
    const pwType = ref("password");
    const showSuggestions = ref(false); //展示账号下拉框
    const filteredSuggestions = ref([]);
    onMounted(() => {
      filteredSuggestions.value = platformStore().filteredSuggestions;
    });
    bus.$on("showKfSec", () => {
      showKfCode.value = true;
    });
    const suggestionType = ref(false); //是否为免登录的账号
    // 点击账号框
    const selectSuggestion = suggestion => {
      inputUserId.value = suggestion.id;
      localStorage.setItem("token", suggestion.token);
      localStorage.setItem("mobile", suggestion.id);
      localStorage.setItem("loginType", "filteredSuggestions");
      suggestionType.value = true;
      userPw.value = "qcplay666";
      showSuggestions.value = false;
    };
    const hideSuggestions = () => {
      // 延迟隐藏，以便在点击选项时不会立即隐藏
      setTimeout(() => {
        showSuggestions.value = false;
      }, 100);
    };
    const deleteSuggestion = index => {
      filteredSuggestions.value.splice(index, 1);
      localStorage.setItem("filteredSuggestions", JSON.stringify(filteredSuggestions.value));
    };
    const getSuggestion = () => {
      showSuggestions.value = true;
      filteredSuggestions.value = platformStore().filteredSuggestions;
    };
    const notLoginChecked = ref(true); //30天登录提示
    const rememberPassword = ref(true); //记住密码
    // 监听免密登录按钮的点击
    const checkNoLogin = () => {
      platformStore().changeLogin(notLoginChecked.value);
    };
    // 是否支持蜂窝网络
    const cellularCanUse = ref(false);
    // 蜂窝网络登录
    const LoginByCellular = () => {};
    const showKfCode = ref(false);
    const kfBtn = () => {
      openTypeFrom.value = "kf";
      showKfCode.value = true;
    };
    const openTypeFrom = ref(""); //判断从哪个入口打开该弹窗
    const closeKfCode = () => {
      if (openTypeFrom.value) {
        openTypeFrom.value = "";
        showKfCode.value = false;
        return;
      }
      context.emit("closeLoginContainer");
      setTimeout(() => {
        showKfCode.value = false;
      }, 500);
    };
    const isFocusPwLogin = ref(false);
    // 监听密码登陆框输入
    watch(inputUserId, newValue => {
      if (newValue && isFocusPwLogin.value) {
        suggestionType.value = false;
        userPw.value = "";
      }
    }, {
      deep: true
    });
    // 密码登陆框聚焦
    const inputFocus = () => {
      isFocusPwLogin.value = true;
    };
    // 密码登陆框失焦
    const outputFocus = () => {
      isFocusPwLogin.value = false;
    };
    //温馨提示弹窗
    const showTips = ref(false);
    const agreeBtn = () => {
      checked.value = true;
      showTips.value = false;
    };
    return {
      agreeBtn,
      showTips,
      isFocusPwLogin,
      outputFocus,
      inputFocus,
      closeKfCode,
      openTypeFrom,
      showKfCode,
      suggestionType,
      LoginByCellular,
      cellularCanUse,
      checkNoLogin,
      notLoginChecked,
      rememberPassword,
      getSuggestion,
      deleteSuggestion,
      filteredSuggestions,
      showSuggestions,
      selectSuggestion,
      hideSuggestions,
      handleKeydownSms,
      pwType,
      kfBtn,
      clearInput,
      goYhxy,
      goYsxy,
      disabled,
      codeInput,
      loginSms,
      sendMsg,
      phoneInput,
      send,
      closeLoginContainer,
      gameLogins,
      inputUserId,
      userPw,
      LoginBtn,
      loginActive,
      timer,
      checked
    };
  }
};