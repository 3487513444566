// 导入axios实例
import httpRequest from "@/request/index"
import base from "@/request/base"
import qs from "qs" //
// 获取登录信息
export function gameLogin(params) {
  return httpRequest({
    url: `${base.url}/web/login/account`,
    method: "post",
    data: qs.stringify(params),
  })
}
//获取游戏列表
export function getList() {
  return httpRequest({
    url: `${base.url}/v3/pay/official/list`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
//发送短信（登录用）
export function sms(params) {
  return httpRequest({
    url: `${base.url}/web/send/sms`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 短信验证码登录
export function smsLogin(params) {
  return httpRequest({
    url: `${base.url}/web/login/sms`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取游戏列表
export function getGameList(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/list${qs.stringify(params)}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 生成支付订单
export function GeneratingOrder(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/submit`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 充值订单
export function order(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/order?${qs.stringify(params)}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
//轮播
export function banner() {
  return httpRequest({
    url: `${base.url}/v3/pay/official/banner`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 生成支付web单
export function webPay(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取充值游戏角色（旧版）
export function getRole(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/roles?os=${params.os}&game_id=${params.game_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// pc微信支付轮询查询订单
export function pcWechatType(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/order/${params.trade_no}?game_id=${params.game_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 微信支付校验接口（微信授权）
export function wechatPayCashier(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/cashier/${params.cashier}?open_id=${params.open_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
//微信授权
export function wechatAuthorization() {
  return httpRequest({
    url: `${base.wechat}/wechat/info`,
    method: "get",
    withCredentials: true,
  })
}
// jssdk
export function settingShare(params) {
  return httpRequest({
    url: `${base.wechat}/wechat/jssdk`,
    method: "post",
    data: qs.stringify(params),
  })
}
//获取玩家补款信息
export function compensate(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/compensate/?game_id=${params.gameId}&token=${params.token}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 补款支付调取
export function compensatePay(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/compensate`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 游戏详情
export function gameDetail(game, role, server_id) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/show/${game}?role_id=${
      role || ""
    }&server_id=${server_id || ""}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取积分商城游戏tab
export function gameSort() {
  return httpRequest({
    url: `${base.url}/v3/pay/web/shop/category`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取总的积分
export function getPoints() {
  return httpRequest({
    url: `${base.url}/v3/pay/web/points/user/total`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取商品列表
export function getProductList(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/points/product?game_id=${params.game_id}&limit=100&type=${params.type}&role_id=${params.role_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取积分商城积分列表
export function pointsList(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/points/user/list?type=${params.type}&game_id=${params.game_id}$half=1`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 积分商城商品兑换
export function pointsExchange(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/points/submit`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 积分商城游戏角色获取
export function pointsMallRole(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/role/all?os=${params.os}&game_id=${params.game_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 校验积分剩余库存
export function checkProduct(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/points/product/stock`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
//删除用户地址
export function delAddress(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/user/address?id=${params.id}`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取用户地址
export function getAddressList() {
  return httpRequest({
    url: `${base.url}/v3/pay/web/user/address`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 修改用户地址
export function modifyAddress(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/user/address`,
    method: "put",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 实名认证
export function updateUser(params) {
  return httpRequest({
    url: `${base.url}/web/account`,
    method: "put",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 用户添加地址
export function addAddressApi(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/user/address`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取兑换记录列表对应游戏
export function getExchangeGameList() {
  return httpRequest({
    url: `${base.url}/v3/pay/web/shop/category/game/list`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取兑换记录跟积分明细
export function getRecordList(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/points/user/list?type=${params.type}&game_id=${params.game_id}&half=${params.half}&page=${params.page}&limit=${params.limit}&status=${params.status}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 代付付款订单提交
export function payForOther(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/pay_behalf`,
    method: "post",
    data: qs.stringify(params),
  })
}
// 购物车代付订单提交
export function payForOtherCart(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/pay_behalf_cart`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取代付订单数据
export function getPayForOtherList(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/pay_behalf/detail/${params.order_no}`,
    method: "get",
  })
}
// 第三方跳转登录
export function getPayForOtherLogin(params) {
  return httpRequest({
    url: `${base.url}/web/config/jump/token/${params.token}`,
    method: "get",
  })
}
// 极光一键登录
export function jpushLogin(params) {
  return httpRequest({
    url: `${base.url}/web/login/aurora`,
    method: "post",
    data: qs.stringify(params),
  })
}
// 购物车记录上传
export function addShoppingCart(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/cart`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 购物车订单提交
export function submitShoppingCart(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/cart/submit`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取角色列表（新）
export function newGetRoleList(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/roles_list?game_id=${params.game_id}&os=${params.os}&parent_id=${params.parent_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 最近角色保存
export function saveRecentRole(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/roles/save`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取游戏角色（用于H5游戏）
export function getH5RoleList(params) {
  return httpRequest({
    url: `${base.url}/web/user/game/token/${params.gameId}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 免费礼包领取
export function freeGift(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/pack/submit`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 悬浮窗奖励领取
export function reward(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/official/bonus/submit`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 获取抽奖列表
export function getLotteryList(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/lottery/index?limit=10&page=1&game_id=${params.game_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 抽奖
export function lottery(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/lottery/submit`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
// 抽奖地址确认
export function lotteryConfirm(params) {
  return httpRequest({
    url: `${base.url}/v3/pay/web/lottery/address`,
    method: "put",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}
