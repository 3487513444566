import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from './../../assets/image/qcpay/h5/menu.png';
import _imports_1 from './../../assets/image/qcpay/h5/close.png';
import _imports_2 from './../../assets/image/payList/desktop.png';
const _hoisted_1 = {
  class: "top"
};
const _hoisted_2 = {
  class: "top-right-title"
};
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  key: 1,
  class: "login-btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["top-list", [$setup.showList ? 'show-m-list-border' : '']])
  }, [_createElementVNode("div", {
    class: "icon",
    onClick: _cache[0] || (_cache[0] = $event => $setup.goHomePage())
  }), _createElementVNode("div", {
    class: "top-left-title",
    onClick: _cache[1] || (_cache[1] = $event => $setup.goHomePage())
  }, " 青瓷游戏官方充值中心 "), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["right-title", [$setup.isTabbar == 1 ? 'show-bot-line' : '']]),
    onClick: _cache[2] || (_cache[2] = $event => $setup.goPayPage())
  }, _cache[14] || (_cache[14] = [_createElementVNode("div", {
    class: "is-active"
  }, "游戏一览", -1)]), 2), _createElementVNode("div", {
    class: _normalizeClass(["right-title", [$setup.isTabbar == 2 ? 'show-bot-line' : '']]),
    onClick: _cache[3] || (_cache[3] = $event => $setup.goOrderPage())
  }, _cache[15] || (_cache[15] = [_createElementVNode("div", {
    class: "is-active"
  }, "我的订单", -1)]), 2), _createElementVNode("div", {
    class: "right-title",
    onClick: _cache[4] || (_cache[4] = $event => $setup.goKf())
  }, _cache[16] || (_cache[16] = [_createElementVNode("div", {
    class: "is-active"
  }, "联系客服", -1)]))]), _createElementVNode("div", {
    class: "click-btn",
    onClick: _cache[5] || (_cache[5] = $event => $setup.showTabrList())
  }, [!$setup.showList ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4))]), _createElementVNode("div", {
    class: "click-btn-desktop",
    onClick: _cache[6] || (_cache[6] = $event => $setup.deskTopBtn())
  }, [_cache[17] || (_cache[17] = _createElementVNode("img", {
    src: _imports_2,
    alt: ""
  }, null, -1)), !$setup.isPc ? (_openBlock(), _createElementBlock("div", _hoisted_5, "添加桌面")) : (_openBlock(), _createElementBlock("div", _hoisted_6, "收藏网页"))]), $setup.type == 1 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "login-btn",
    onClick: _cache[7] || (_cache[7] = $event => $setup.showLogin())
  }, _cache[18] || (_cache[18] = [_createElementVNode("span", {
    class: "hello-title"
  }, "您好，", -1), _createElementVNode("span", {
    class: "loginout-btn"
  }, "请登录", -1)]))) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createTextVNode(_toDisplayString($setup.mobile) + " ", 1), _createElementVNode("span", {
    onClick: _cache[8] || (_cache[8] = $event => $setup.showLogin()),
    class: "loginout-btn"
  }, "注销")]))], 2), _createElementVNode("div", {
    class: _normalizeClass(["top-list-m", [$setup.showList ? 'show-m-list' : '']])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["right-title", [$setup.isTabbar == 1 ? 'choice-tab' : '']]),
    onClick: _cache[9] || (_cache[9] = $event => $setup.goPayPage())
  }, " 游戏一览 ", 2), _createElementVNode("div", {
    class: _normalizeClass(["right-title", [$setup.isTabbar == 2 ? 'choice-tab' : '']]),
    onClick: _cache[10] || (_cache[10] = $event => $setup.goOrderPage())
  }, " 我的订单 ", 2), _createElementVNode("div", {
    class: "right-title",
    onClick: _cache[11] || (_cache[11] = $event => $setup.goKf())
  }, "联系客服")], 2), _createVNode(_Transition, {
    name: "van-fade"
  }, {
    default: _withCtx(() => [$setup.showList ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "show-m-list-bgc",
      onClick: _cache[12] || (_cache[12] = $event => $setup.showList = false)
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, {
    name: "van-fade"
  }, {
    default: _withCtx(() => [$setup.desktopPopupPc ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "show-m-list-bgc-sec",
      onClick: _cache[13] || (_cache[13] = $event => $setup.desktopPopupPc = false)
    }, _cache[19] || (_cache[19] = [_createElementVNode("div", {
      class: "collect-container"
    }, [_createElementVNode("div", {
      class: "collect-title"
    }, "收藏网页"), _createElementVNode("div", {
      class: "collect-text"
    }, [_createTextVNode(" 在导航栏"), _createElementVNode("span", null, "点击收藏图标"), _createTextVNode("或"), _createElementVNode("span", null, "ctrl+D"), _createTextVNode("，即可收藏网页 ")]), _createElementVNode("div", {
      class: "collect-img"
    }), _createElementVNode("div", {
      class: "collect-btn"
    }, "知道了")], -1)]))) : _createCommentVNode("", true)]),
    _: 1
  })]);
}