import "core-js/modules/es.array.push.js";
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import "vant/lib/index.css";
import bus from "@/lib/bus";
export default {
  props: ["token"],
  setup(props, context) {
    const isTabbar = ref(1);
    const type = ref(1); //1未登录2已登录
    const showList = ref(false);
    const mobile = ref("");
    const showTabrList = () => {
      showList.value = !showList.value;
    };
    const showLogin = () => {
      if (type.value == 2) {
        localStorage.removeItem("token");
        type.value = 1;
        localStorage.removeItem("mobile");
        mobile.value = "";
        bus.$emit("clear");
      }
      context.emit("showLogin");
    };
    // 登录状态
    const tokenType = props;
    watch(tokenType, newValue => {
      if (newValue) {
        type.value = 2;
        mobile.value = localStorage.getItem("mobile");
      }
    });
    // 前往官方充值页面
    const route = useRouter();
    const goPayPage = () => {
      showList.value = false;
      isTabbar.value = 1;
      route.push({
        path: "./home"
      });
    };

    // 前往订单页面
    const goOrderPage = () => {
      showList.value = false;
      if (!localStorage.getItem("token")) {
        bus.$emit("click");
        return;
      }
      isTabbar.value = 2;
      route.push({
        path: "./orderpage"
      });
    };
    const goHomePage = () => {
      route.push({
        path: "./home"
      });
    };
    const goJfSC = () => {
      showList.value = false;
      isTabbar.value = 3;
      route.push({
        path: "./pointmall"
      });
    };
    document.getElementsByClassName("top-list");
    bus.$on("changeTab", e => {
      isTabbar.value = e;
    });
    bus.$on("login", () => {
      type.value = 2;
      mobile.value = localStorage.getItem("mobile");
    });
    const goKf = () => {
      bus.$emit("showKf");
    };
    const desktopPopupPc = ref(false); //引导添加到主屏幕的弹窗状态(pc端)

    const isPc = ref(false);
    onMounted(() => {
      if (localStorage.getItem("token")) {
        type.value = 2;
        mobile.value = localStorage.getItem("mobile");
      }
      //是否展示引导添加到主屏幕的弹窗的按钮
      // 是否为pc端
      if (window.innerWidth > 768) {
        isPc.value = true;
      }
    });
    bus.$on("loginOut", () => {
      showLogin();
      localStorage.removeItem("token");
      mobile.value = "";
      localStorage.removeItem("mobile");
      localStorage.removeItem("pointsNum");
    });
    const deskTopBtn = () => {
      if (window.innerWidth > 768) {
        desktopPopupPc.value = true;
        return;
      }
      bus.$emit("deskTop");
    };
    // 每日弹窗监听
    bus.$on("deskTopLenten", () => {
      deskTopBtn();
    });
    return {
      deskTopBtn,
      isPc,
      desktopPopupPc,
      goHomePage,
      mobile,
      isTabbar,
      goKf,
      goOrderPage,
      goPayPage,
      type,
      showList,
      showTabrList,
      showLogin,
      goJfSC
    };
  }
};